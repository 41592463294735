<script>
  import { HeroGeneric, HeroSecondary, HeroPrimary } from "@ObamaFoundation/of-design-system";
  import { setContext } from "svelte";
  import FilterList from "$lib/contentTypes/FilterList.svelte";
  import { filterListIsOpen, filterItemsStore } from "$lib/stores";

  export let data;
  export let component = HeroGeneric;

  setContext("containerized", true);

  const { featuredImage, featuredStory, cta, subtitle } = data;
  const isTextOnly = !featuredImage && !featuredStory && !cta && !subtitle;

  // To avoid regressions we remove the margin on demand for components and cases with extra margins
  const getMargin = () => {
    if (component === HeroSecondary && (isTextOnly || featuredImage)) {
      return "mb-0";
    } else if ((component === HeroGeneric || component === HeroPrimary) && isTextOnly) {
      return "mb-0";
    }
    return "mb-15 md:mb-20 lg:mb-25";
  };

  let filterItems;
  filterItemsStore.subscribe((value) => {
    if (data.filters) {
      filterItems = value;
    }
  });

  function toggleFilterList() {
    filterListIsOpen.update((isOpen) => {
      return !isOpen;
    });
  }

  function handleDeselectFilterItem(e) {
    const newFilterItems = filterItems?.filter(
      (filterItem) => filterItem.id !== e.detail.filterItemId
    );
    filterItemsStore.set(newFilterItems);
  }
</script>

<!-- TODO: Remove margins from this component.
If this is wrapping a single component the margins should be handled on the component itself -->
<div class="-mt-[var(--nav-height)] {getMargin()}">
  <svelte:component
    this={component}
    {...data}
    {filterItems}
    class="pt-[var(--nav-height)]"
    on:toggleFilterList={toggleFilterList}
    on:deselectFilterItem={handleDeselectFilterItem}
  />
</div>

<!-- TODO(Yang): bind filterItems to the selected options inside FilterList, so when deselecting an option will reflect that on the filter list -->
{#if data.filters}
  <FilterList data={data.filters} />
{/if}
