<script>
  import { Filters, Rule, Button } from "@ObamaFoundation/of-design-system";
  import { createEventDispatcher } from "svelte";

  /**
   * @type {string}
   */
  export let id = "filterList";

  /**
   * The filter options that will appear in the filter list
   * @type {[]}
   */
  export let filters = undefined;

  export let containerClass;

  /**
   * Specifies whether or not the filter list is hidden.
   */
  export let isHidden = true;

  export let selections = [];

  export let totalSelectedOptions = 0;

  const dispatch = createEventDispatcher();

  const handleClear = async () => {
    totalSelectedOptions = 0;
    dispatch("clear");
  };

  const handleClose = () => {
    dispatch("close", {
      isHidden: true
    });
  };

  const handleSubmit = () => {
    dispatch("submit", {
      value: selections
    });

    handleClose();
  };

  const syncCheckboxes = (e) => {
    return filters?.map((filterItem) => {
      if (filterItem.headline === e.detail.name) {
        filterItem.bodyProps.options.forEach((option) => {
          if (option.id === e.detail.checkbox.id) {
            option.isChecked = e.detail.checkbox.value;
          }
        });
      }
      return filterItem;
    });
  };

  const handleCheckBoxSelectionGroup = (e) => {
    filters = syncCheckboxes(e);

    const checkboxState = e.detail.checkbox.value;

    if (checkboxState) {
      totalSelectedOptions += 1;
      if (
        !selections.find((selection) => {
          return selection.id === e.detail.checkbox.id;
        })
      ) {
        selections.push({ ...e.detail.checkbox, group: e.detail.name });
      }
    } else {
      totalSelectedOptions -= 1;
      selections = selections.filter((selection) => {
        return selection.id !== e.detail.checkbox.id;
      });
    }
  };
</script>

{#if !isHidden && filters}
  <div
    {id}
    role="menu"
    class="bg-light-grey w-[300px] md:w-[304px] lg:w-[452px] xl:w-[528px] px-15 pt-15 {containerClass}"
  >
    <div class="flex flex-row justify-between items-center">
      <div aria-live="polite">
        <p class="inline label">Filter</p>
        {#if totalSelectedOptions}
          <p class="inline label">
            <span>
              {`(${totalSelectedOptions} selected): `}
            </span>
            <Button
              id="clear-filters"
              class="underline"
              ariaLabel="Clear All Selected Filters"
              on:click={handleClear}>Clear All</Button
            >
          </p>
        {/if}
      </div>
      <Button
        on:click={handleClose}
        id="close-filter-panel"
        iconName="close"
        ariaLabel="Close Filter Menu"
        buttonClass="border-none px-0 py-0"
      />
    </div>
    <Rule class="mt-5 mb-6 md:mb-14 lg:mb-7 xl:mb-20" type="thick" />
    <div class="h-[calc(90vh-200px)] overflow-y-scroll">
      <Filters
        componentName="checkbox"
        {filters}
        itemContainerClass="first-of-type:border-none lg:[&:not(:first-of-type)]:!pt-4 px-2"
        on:checkBoxSelectionGroup={handleCheckBoxSelectionGroup}
      />
    </div>
    <div
      class="bg-light-grey fixed bottom-0 left-0 w-[300px] h-[112px] md:w-[304px] lg:w-[452px] lg:h-[146px] xl:w-[528px] xl:h-[162px] px-15 flex items-center"
    >
      <Button
        on:click={handleSubmit}
        id="apply-filters"
        disabled={totalSelectedOptions < 1}
        variant="filled"
        label="Apply"
        buttonClass="w-full justify-center"
      />
    </div>
  </div>
{/if}
