<script>
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  import logo from "$lib/assets/logo.png";
  import { correctedUrl, isRootRelative } from "$lib/utilities/url-helpers";

  export let data = {};
  export let errorPage = false;

  const normalizeContentfulAssetUrl = (url) => (url ? `https:${url}` : "");

  function createCanonicalUrl() {
    let relativePath = $page?.url?.pathname; // starts with "/"
    if (data.seoCanonicalLink) {
      relativePath = isRootRelative(data.seoCanonicalLink)
        ? correctedUrl(data.seoCanonicalLink)
        : correctedUrl(`/${data.seoCanonicalLink}`);
    }
    let baseUrl = import.meta.env.VITE_BASE_URL || $page?.url?.origin;
    if (baseUrl.endsWith("/")) {
      // remove trailing slash
      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }
    return `${baseUrl}${relativePath}`;
  }
</script>

<svelte:head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta name="google-site-verification" content="gd_ad0RSSgAZkAwXnOEALm4CavCD-a3uSIpVnEBBUOw" />

  <!-- favicon -->
  <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
  <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
  <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
  <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
  <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
  <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
  <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
  <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
  <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
  <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/manifest.json" />
  <meta name="msapplication-TileColor" content="#ffffff" />
  <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
  <meta name="theme-color" content="#ffffff" />
  <link rel="icon" type="image/x-icon" href="/favicon.ico" />

  <!-- general meta data -->
  <!-- meta tags that Google understands: https://developers.google.com/search/docs/advanced/crawling/special-tags -->
  <title>{data.headline} | The Obama Foundation</title>
  <meta name="title" content={data.seoTitle || data.headline} />
  <meta name="description" content={data.seoMetaDescription} />
  <meta name="author" content="Obama Foundation" />
  <meta name="subject" content={data.seoKeyWords?.join(", ")} />
  <meta name="keywords" content={data.seoKeyWords?.join(", ")} />

  {#if !errorPage}
    <!-- Infinite loop in the build if we try to use the original URL in this spot -->
    <link rel="canonical" href={createCanonicalUrl()} />
  {/if}

  <meta
    name="robots"
    content={import.meta.env.VITE_SEO_ROBOT_OVERRIDE ||
      [data.seoNoIndex ? "noindex" : "index", data.seoNoFollow ? "nofollow" : "follow"].join(",")}
  />

  <!-- Twitter Card data https://dev.twitter.com/cards/overview -->
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:site" content="@ObamaFoundation" />
  <meta name="twitter:creator" content="@ObamaFoundation" />

  <!-- Open Graph data http://ogp.me/ -->
  <!-- required: used as Twitter Card fallback -->
  <meta property="og:title" content={data.seoTitle || data.headline} />
  <meta property="og:type" content="article" />
  <meta property="og:url" content={createCanonicalUrl()} />
  <meta
    property="og:image"
    content={normalizeContentfulAssetUrl(
      data.previewImageComponent?.fields?.imageAsset?.fields?.file?.url
    ) || logo}
  />
  <meta property="og:image:alt" content={data.previewImageComponent?.fields?.altText} />
  <meta property="og:image:width" content="2108" />
  <meta property="og:image:height" content="1200" />
  <!-- recommended -->
  <meta property="og:description" content={data.seoMetaDescription} />
  <meta property="og:locale" content="en_US" />
  <meta property="og:site_name" content="Obama Foundation" />
</svelte:head>
