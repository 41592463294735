<script>
  import FilterList from "$lib/components/FilterList.svelte";
  import { generateFilterOptions } from "$lib/utilities/careers.js";
  import { textToId } from "$lib/utilities/dom-helpers";
  import Checkbox from "@ObamaFoundation/of-design-system/Checkbox";
  import { filterListIsOpen, filterItemsStore } from "$lib/stores";
  import { onDestroy } from "svelte";

  export let data;

  export let isHidden = true;

  export let unsubscribeFilterListOpen = filterListIsOpen.subscribe((isOpen) => {
    isHidden = !isOpen;
  });

  $: backgroundClass = isHidden
    ? "hidden"
    : "fixed inset-0 backdrop-brightness-75 z-[--stack-level-drawer-background]";

  let filters;

  let selections = [];

  $: totalSelectedOptions = selections.length;

  const resetFilters = () => {
    filters = data.fields.items.map((filterItem) =>
      generateFilterOptions(filterItem.fields.name, Checkbox, {
        options: [
          ...filterItem.fields.tags.map((tag) => {
            return {
              label: tag.fields.name,
              id: `filter-${textToId(tag.fields.name.trim())}`,
              isChecked: false
            };
          })
        ],
        name: filterItem.fields.name,
        componentClass: "lg:pt-[4px] lg:pb-4"
      })
    );
  };

  resetFilters();

  const syncSelectionsOnStoreUpdate = (filterItems) => {
    const filterItemIds = filterItems.map((filterItem) => {
      return filterItem.id;
    });

    return filters?.map((filterItem) => {
      filterItem.bodyProps.options.forEach((option) => {
        if (filterItemIds.includes(option.id)) {
          option.isChecked = true;
        } else {
          option.isChecked = false;
        }
      });
      return filterItem;
    });
  };

  const unsubscribeFilterItemsStore = filterItemsStore.subscribe((filterItems) => {
    selections = filterItems;
    filters = syncSelectionsOnStoreUpdate(filterItems);
  });

  onDestroy(() => {
    unsubscribeFilterListOpen();
    unsubscribeFilterItemsStore();
  });

  let handleClose = () => {
    filterListIsOpen.update((isOpen) => {
      return !isOpen;
    });
    isHidden = true;
  };

  export let handleSubmit = () => {
    filterItemsStore.update(() => {
      return selections;
    });
  };

  const handleClear = () => {
    selections = [];
    resetFilters();
  };
</script>

<div
  data-testid="dimmed-background"
  on:click={handleClose}
  aria-hidden="true"
  class={backgroundClass}
></div>

<FilterList
  on:close={handleClose}
  on:submit={handleSubmit}
  on:clear={handleClear}
  on:checkBoxSelectionGroup
  bind:totalSelectedOptions
  bind:selections
  bind:filters
  id="filterList"
  containerClass="fixed top-0 left-0 z-[--stack-level-drawer] h-screen"
  {isHidden}
/>
